<template>
  <div class="wrapper -quienes-somos">

    <div class="encabezado">
      <div class="container">
        <h2>Quiénes somos</h2>
      </div>
    </div>

    <div class="quienes-somos-body">
      <div class="container row-start ais">
        <h2 class="title">La compañía</h2>
        <img src="/img/imagen-quienes-somos.jpg" alt="">
        <div class="texto">
          <p>Desarrollamos tecnologías inteligentes y servicios pioneros para una movilidad sostenible y conectada en la
            automoción y transporte para vehículo industrial, especial y turismo.</p>
          
          <a class="showmore" @click="showmore = !showmore">
            <img src="/img/more.svg" alt="Ver más">
          </a>
          <div class="hide" :show="showmore">
            <p>Con nuestras marcas, Continental, VDO, ATE y Galfer ofrecemos productos electrónicos, sistemas y
              servicios para vehículo industrial y vehículos especiales así como un amplio abanico de productos para
              talleres especializados y recambio original para el mercado de aftermarket y equipos originales para los
              fabricantes.</p>
            <a class="showmore" @click="showmore = !showmore">
              <img src="/img/menos.svg" alt="Ver menos">
            </a>
          </div>
        </div>
      </div>
    </div>

    
    <div class="donde-estamos container">

      

      <div class="text">
        <h2>Somos Continental Automotive Spain</h2>
        <p>Puedes encontrarnos en Calle de Sepúlveda, 11, 28108 Alcobendas, Madrid.
        </p>
      </div>

      <div class="mapa">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3032.646492769861!2d-3.655420312849019!3d40.52730405030713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422c7a5d100a15%3A0x8583a553843e8e37!2sCalle%20de%20Sep%C3%BAlveda%2C%2011%2C%2028108%20Alcobendas%2C%20Madrid!5e0!3m2!1ses!2ses!4v1619432286177!5m2!1ses!2ses" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>

    </div>



    <div class="marcas">
      <div class="container">
        <h2 class="title">Nuestras marcas</h2>
        <div class="marcas-list row-center">
          <a href="https://www.continental.com/en" target="_blank"><img src="/img/marca-continental.svg" alt="Continental"></a>
          <a href="https://www.vdo.es/" target="_blank"><img src="/img/marca-vdo.png" alt="VDO"></a>
          <a href="https://www.frenos-ate.es/" target="_blank"><img src="/img/marca-ate.png" alt="ATE Frenos"></a>
          <a href="http://www.galfer-frenos.es/" target="_blank"><img src="/img/marca-galfer.png" alt="Galfer"></a>
        </div>
      </div>
    </div>


  </div>



</template>


<script>
  export default {
    name: 'quienes-somos',
    data() {
      return {
        showmore: false
      }
    }
  }
</script>